import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";


const BPUDashContent = () => {
    const navigate = useNavigate();

    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');
    const bpuID = localStorage.getItem('userId');

    const HandleNewOrderClick = (selectedDistributorId) => {
        localStorage.setItem('distributorId', selectedDistributorId);
        navigate("/NewOrder");
    };


    const HandleNewPaymentClick = (selectedDistributorId) => {
        localStorage.setItem('distributorId', selectedDistributorId);
        navigate("/NewPayment/SubmitPayment");
    };

    const HandleDistributorReportsClicked = (selectedDistributorId) => {
        localStorage.setItem('distributorId', selectedDistributorId);
        navigate("/BPUDashboard/DistributorReports");
    };



    const { data, error, isLoaded } = GetRequest(`/User/GetDistributorsAssignedToUser/${bpuID}`);

    if (error !== null) {
        return <Error data="My Distributor" />;
    }
    if (!isLoaded) {
        return <Loading />;
    }

    function textFilterProducts() {
       // alert('test1');
        try{
        var input, filter, ul, li, a, i, txtValue;
        input = document.getElementById("searchProductFilter");
        //alert('test2');
        filter = input.value.toUpperCase();
        ul = document.getElementById("bpulistfilter");
      
        li = ul.getElementsByTagName("label");
        for (i = 0; i < li.length; i++) {
            a = li[i];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].parentElement.style.display = "";
            } else {
                li[i].parentElement.style.display = "none";
            }
        }
    }
    catch(e)
    {

    }
    }



    return (
        <>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary">
                                <div className="card-header topnav">
                                    <h3 className="card-title"> My Distributor  List</h3>
                                    <div className="search-container">
                                        <input type="text" id="searchProductFilter" onKeyUp={textFilterProducts} placeholder="Search by Distributor Name .." name="search" />
                                        <button type="submit"><i className="fa fa-search"></i></button>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <div className="row" id="bpulistfilter">
                                        {
                                            data.result && data.result.map((item, index) => (
                                                <div className='col-6 duname'>
                                                    <label id={item.id}> {item.distributorDisplayName}</label>
                                                    <div className='btnGroupFoot'>
                                                        <Link to={`/BPUDashboard/PaymentList/${item.id}`} className='btn btn-success btn-sm'>  Payments   </Link> &nbsp;
                                                        <Link to={`/BPUDashboard/OrderList/${item.id}`} className='btn btn-primary btn-sm'>  Orders    </Link>
                                                        <button className='btn btn-success btn-sm' onClick={() => { HandleNewOrderClick(`${item.id}`); }}> New Order </button>
                                                        <button className='btn btn-success btn-sm' onClick={() => { HandleNewPaymentClick(`${item.id}`); }}> New Payment </button>
                                                        <button className='btn btn-success btn-sm' onClick={() => { HandleDistributorReportsClicked(`${item.id}`); }}> Reports </button>                                              
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-6">
                    <div className="card card-primary">
                        <div className="card-header">
                            <h3 className="card-title"> Payment List  </h3>
                        </div>

                        <div className="card-body">
                            <div className="row">
                                
                            </div>
                        </div>
                    </div>
                </div> */}
                    </div>

                </div>
            </section>
        </>
    )
}

export default BPUDashContent