import React from 'react'
import GetRequest from '../Services/APIRequest'


const TotalOutStandingAmountBox = () => {

  const distID = localStorage.getItem('distributorId');
  const boostID = localStorage.getItem('companyIdBooster');
  const parisID = localStorage.getItem('companyIdParis');

  const { data } = GetRequest(`/Account/GetTotalOutStandingAmount/${distID}/${parseInt(boostID)}`);

  if (data.result !== '') {
    return (
      <div className="col-lg-3 col-6">
        <div className="small-box scol4">
          <div className="inner">
            <h4>{data.result}</h4>
            <p>Total OutStanding Amount</p>
          </div>
          <div className="icon">
          <i className=" fa fa-inr"></i>
          </div>
         
        </div>
      </div>
    )
  } else {
    return (
      <> </>
    )
  }

}

export default TotalOutStandingAmountBox