import React from 'react'
import GetRequest from '../Services/APIRequest'

const CreditBalanceParisBox = () => {
    
  const distID = localStorage.getItem('distributorId');
  const boostID = localStorage.getItem('companyIdBooster');
  const parisID = localStorage.getItem('companyIdParis'); 

  const { data } = GetRequest(`/Account/GetCurrentBalance/${distID}/${parseInt(parisID)}`);
  if(data.result !== '') {
  return (
    <div className="col-lg-3 col-6">
        <div className="small-box scol2">
            <div className="inner">
                <h4>INR {data.result} <sub ></sub></h4> 
                <p>
                    Paris Current Balance
                </p>
            </div>
            <div className="icon">
                <i className="fa fa-inr"></i>
            </div> 
        </div>
    </div>
  )
} else {
    return (
      <> </>
     )
  }
}

export default CreditBalanceParisBox