import React from "react";
import axios from 'axios';
import { useState } from 'react';
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link, Outlet } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import MyOrderAlert from "./MyOrderAlert";

const MyOrderDashboard = () => {
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');
    const userId = localStorage.getItem('userId');
    const userType = localStorage.getItem('userType');
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState("");

    const { data, error, isLoaded } = GetRequest(`/Orders/GetRecentOrders/${distID}/${userType}/${userId}`);

    if (error !== null) {
        return <Error data=" My Orders " />;
    }
    if (!isLoaded) {
        return <Loading />;
    }

    const handleEditOrder = (event) => {
        event.preventDefault();
        const editOrd = event.target.id;
        const fData = {};
        fData.orderLineId = editOrd;
        axios.post("https://dpapi.boosterparis.in/api/Orders/EditOrderLine", fData, {
            headers: {
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
                'Authorization': localStorage.getItem("loginToken"),
            },
        })
            .then((response) => {
                if (response.data.isSuccess === true) {

                    localStorage.setItem('ordereditOnOrderSummary', true);
                    navigate("/NewOrder/OrderSummary");
                } else {
                    document.getElementById(`showerror${editOrd}`).classList.remove('hide')
                    setErrorMsg(response.data.message)
                    setTimeout(function () {
                        document.getElementById(`showerror${editOrd}`).classList.add('hide')
                    }, 5000);
                }
            }).catch((response) => {
                // if(response.response.status === 401){  
                //     window.location.replace("/Logout");
                //  }
                console.log(response.statusText);
            });


    }
    if (data.result.length !== 0) {
        return (
            <>

                <section className="content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-md-12">
                                <MyOrderAlert />

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-success">
                                    <div className="card-header">
                                        <h3 className="card-title">My Orders   </h3>
                                    </div>

                                    <div className="card-body">
                                        <div className="row">
                                            {
                                                data.result && data.result.map((item, index) => (

                                                    <div className="col-md-4">
                                                        <div className="card card-primary">
                                                            <div className="card-header ">
                                                                <h5 className="card-title">   {item.orderNumber} </h5>
                                                            </div>
                                                            <div className="card-body">
                                                                <h6>Status : {item.statusDisplayText}</h6>
                                                                <p>Number Of Items : {item.numberOfItems}</p>
                                                                <p>Order Date : {item.orderPlacedDate}</p>
                                                                <p>Amount  : <b>{item.totalWithTax}</b></p>
                                                                <p>For  : <b>{item.shipToDetails}</b></p>
                                                                {item.isOrderEditable == true ? <button className='btn btn-success btn-sm editMO' onClick={handleEditOrder} id={item.id}>Edit </button> : ''}
                                                                <Link to={`/OrderDetails/${item.id}`} className='btn btn-primary btn-sm' id={item.orderNumber}>View Details</Link>
                                                                <p id={`showerror${item.id}`} className="hide">
                                                                    <span className="badge rounded-pill bg-danger">{errorMsg}</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            <Outlet></Outlet>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </>
        )
    } else {
        return (
            <>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-danger">
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            <i className="fa fa-exclamation-triangle"></i>
                                            Order Alerts
                                        </h3>
                                    </div>

                                    <div className="card-body">

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="card card-warning">
                                    <div className="card-header">
                                        <h3 className="card-title">My Orders   </h3>
                                    </div>

                                    <div className="card-body">
                                        <div className="row">
                                            <h4 className="NoDataAvailableText"> No Data Available </h4>
                                            <Outlet></Outlet>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </>
        )
    }
}

export default MyOrderDashboard