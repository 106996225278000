
import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error' 
const PaymentsAgainOutstandings = (props) => {


    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');
    const currCompIdSel = localStorage.getItem('currentSelectedCompId');
    const { data, error, isLoaded } = GetRequest(`/Account/GetCostCentresForOutstandingCollection/${distID}/${currCompIdSel}`);

    if (error !== null) {
        return <Error data="Payment Against Outstandings " />;
    }
    if (!isLoaded) {
        return <Loading />;
    }
    console.log(data)

    return (
        <>
                <div id="accordion1">
                    <h5 className='invHead' data-bs-toggle="collapse" href={`#collapseAgainsOut`} >Payment Against Outstandings
                        <a className="btn dp-right-mt5 accUIIcon">
                            <i className="fa fa-angle-double-down"></i>
                            <i className="fa fa-angle-double-up hide"></i>
                        </a>
                    </h5>
                    <div id={`collapseAgainsOut`} className="collapse" data-bs-parent="#accordion1">  
                        <div className='card-body'>
                            {
                                data.result && data.result.map((item, index) => (
                                    <div className='row sepSecLineOutAmt'>
                                        <div className='col-sm-6'>
                                            <p> <b>{item.costCentreDisplayName}</b></p>
                                        </div>
                                        

                                        <div className='col-sm-6'>
                                            <input type='number' className='form-control payOutText' id={item.costCentreId} name='Amount' onBlur={props.funcname} />
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                </div>
            </div>
        </>
    )

}

export default PaymentsAgainOutstandings