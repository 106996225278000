import React from 'react'
import GetRequest from '../Services/APIRequest'
 

const TotalCreditAmountAddFYBox = () => {
  const distID = localStorage.getItem('distributorId');
  const boostID = localStorage.getItem('companyIdBooster');
  const parisID = localStorage.getItem('companyIdParis'); 

  const { data } = GetRequest(`/Account/GetTotalCreditNotesAmountAddedInFinancialYear/${distID}/${parseInt(boostID)}`);
 
  
if(data.result !== '') {
  return (
    
    <div className="col-lg-3 col-6">
        <div className="small-box scol2">
            <div className="inner">
                <h4>{data.result}</h4> 
                <p>Credit Notes Added In Year</p>
            </div>
            <div className="icon">
             <i className=" fa fa-inr"></i>
            </div>
            
        </div>
    </div>
  )
} else {
  return (
    <> </>
   )
}

}

export default TotalCreditAmountAddFYBox