import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link , useParams} from 'react-router-dom'


const BPUOrderList = () => {
  const Id = useParams();
  const distID = localStorage.getItem('distributorId');
  const boostID = localStorage.getItem('companyIdBooster');
  const parisID = localStorage.getItem('companyIdParis');
  const bpuID = localStorage.getItem('userId');
  const bpuType = localStorage.getItem('userType'); 

  const { data, error, isLoaded } = GetRequest(`/Orders/GetRecentOrders/${Id.Id}/${bpuType}/${bpuID}`);

  if (error !== null) {
    return <Error data="My Distributor Orders " />;
  }
  if (!isLoaded) {
    return <Loading />;
  }
 
  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="card card-success">
            <div className="card-header"> 
              <h3 className="card-title">  Orders </h3>
            </div>
            <div className="card-body no-padding row">  
                                          {
                                            data.result && data.result.map((item, index) => (

                                                <div className="col-md-4">
                                                    <div className="card card-primary">
                                                        <div className="card-header ">
                                                            <h5 className="card-title">   {item.orderNumber} </h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <p>Status : <b>{item.statusDisplayText} </b></p>
                                                            <p>Number Of Items : {item.numberOfItems}</p>
                                                            <p>Order Date : {item.orderPlacedDate}</p>
                                                            {/* <p>Amount  : <b>{item.totalWithTax}</b></p> */}
                                                            {item.orderApprovedBy != null ?  <p>   Approved by: <b>{item.orderApprovedBy}</b>  </p> : ''}
                                                            <Link to={`/BPUDashboard/BPUOrderDetails/${item.id}`} className='btn btn-primary btn-sm' id={item.orderNumber}>View Details</Link>                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
            </div>
          </div> 
        </div>
      </section>
    </>
  )
}

export default BPUOrderList