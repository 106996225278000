import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'

const ProductListByCategory = (props) => {
  var PCID = props.id;
  const { data, error, isLoaded } = GetRequest("/Product/GetProductLinesByCategory/" + PCID);

  if (error !== null) {
    return <Error data=" " />;
  }
  if (!isLoaded) {
    return <Loading />;
  } 
  return (
    <>
      {
        data.result && data.result.map((items, index) => (
          <div className="col-12 col-sm-6 col-md-4  itemfiltered" data-item={PCID} id={items.id}>
            <div className="card ">
              <div className="card-header"> 
                <div className="row">
                    <div className="col-sm-12 centerImage"> 
                    {items.productBigImage == null ? <i className="fa fa-file-image-o dumProdBigIcon"></i> :
                     <img className="ProductsInfoHeadImg" src={items.productSmallImage} alt="Crop image"   /> }  
                      
                    </div>
                </div> 
                <div className="row">
                    <div className="col-sm-12 bgHeadPI"  data-bs-toggle="collapse" href={`#collapse${items.id}`}>
                      <label className="prHeadName">{items.name}</label> 
                      <a className="btn dp-right-mt5">
                        <i className="fa fa-angle-double-down"></i>
                        <i className="fa fa-angle-double-up"></i>
                      </a> 
                    </div>
                </div>
              
              </div>
              <div id={`collapse${items.id}`} className="collapse" data-bs-parent="#accordion">
                <div className="card-body">
                    
                    <p>Technical Name - {items.technicalName} </p>  <br /> 
                    <p>Dosage  - <b>{items.dosageInformation}</b> </p>  <br /> 
                    {/* <label>Company Name - <b>{items.companyName}</b> </label>  <br />   */}
                     
                      
                </div>
              </div> 
            </div> 
          </div> 
        ))
      }
    </>
  )
}

export default ProductListByCategory