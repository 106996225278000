

import React from 'react'
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const BPUOrderDetails = () => {
    const Id = useParams();
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');
    const [inputs, setInputs] = useState({});
    const notifySuccess = () => toast.success('Order approved successfully!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });
    const notifyReject = () => toast.error('Order rejected successfully!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });
    const { data, error, isLoaded } = GetRequest(`/Orders/GetOrderLineDetailsById/${Id.Id}`);
    if (error !== null) {
        return <Error data=" " />;
    }
    if (!isLoaded) {
        return <Loading />;
    }
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    const approvedOrder = (event) => {
        const formData = {};
        formData.orderId = event.target.id;
        formData.orderNumber = event.target.getAttribute('data-trans-id');
        if (event.target.attributes[1].value !== 'Reject') {
            formData.status = 'Approved';
        } else {
            formData.status = 'Rejected';
        }
        formData.comments = inputs.comments;
        formData.approvedByName = localStorage.getItem('distributorDisplayName');
        formData.approvedByTeamAppId = localStorage.getItem('userId');
        formData.approvedByUserId = localStorage.getItem('userId');

        axios.post("https://dpapi.boosterparis.in/api/Orders/ApproveOrder", formData, {
            headers: {
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
                'Authorization': localStorage.getItem("loginToken"),
            },
        })
            .then((response) => {
                if (response.data.isSuccess === true) {
                    if (response.data.result.status !== "Rejected") {
                        notifySuccess();
                    }
                    else {
                        notifyReject();
                    }
                    document.getElementById('statuschangediv').classList.add('hide');
                }

            }).catch((response) => {

                console.log(response.statusText);
            });
    }
    return (
        <>

            <section className="content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title"> Orders  Details - {data.result && data.result.orderNumber}</h3>
                                </div>
                                <div className="card-body">
                                    {

                                        data.result.orders && data.result.orders.map((item, index) => (
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="card ">
                                                        <div className="card-header bg-info">
                                                            <h5 className="card-title">  {item.companyName} Order </h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <h6>Number Of Items - {item.numberOfItems} </h6>
                                                            <h6>  Number Of Items dispatched - {item.numberOfItemsDispatched} </h6>
                                                            <p>   Status - <b>{data.result.statusDisplayText}</b>  </p>

                                                            {
                                                                item.orderLineItemDTOList && item.orderLineItemDTOList.map((items, index) => (
                                                                    <>
                                                                        <table className='table table-bordered'>
                                                                            {/* <thead>
                                                                                <th style={{width: "100%"}}>
                                                                                Item
                                                                                </th>
                                                                                <th>
                                                                                Dispatched/Total
                                                                                </th>
                                                                            </thead> */}
                                                                            <tbody> <tr>
                                                                                <td> {items.productName}</td>
                                                                                <td>{items.dispatchedQuantity} / {items.totalQuantityOrdered}</td>
                                                                            </tr> </tbody>
                                                                        </table>
                                                                    </>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <p>   Remarks: <b>{data.result.orderComments}</b>  </p>
                                        </div>
                                        {data.result.orderApprovedBy != null ?
                                            <div className="form-group">
                                                <p>   Approval Remarks: <b>{data.result.orderStatusChangeComments}</b>  </p>
                                            </div>
                                            :
                                            <div className="form-group">
                                                <label for="Comments " className="form-label"><b>Approval Remarks</b></label>
                                                <textarea name='comments' className="form-control txtDetailsHt" placeholder="Enter Remarks  here ..."
                                                    onChange={handleChange} value={data.result.orderStatusChangeComments} />
                                            </div>
                                        }

                                        {data.result.orderApprovedBy != null && data.result.orderApprovedBy != '' ?
                                            <div className="form-group">
                                                <p>   Approved by: <b>{data.result.orderApprovedBy}</b>  </p>
                                            </div> : ''}
                                    </div>
                                    <div className='bpuFootBtn' id='statuschangediv'>
                                        {data.result.status === "PendingForReview" ? <>
                                            <button className='btn btn-success btn-sm' data-trans-id={data.result && data.result.orderNumber} id={data.result.id} onClick={approvedOrder}>Approve</button> &nbsp;
                                            <button className='btn btn-danger btn-sm' data-text-id="Reject" data-trans-id={data.result && data.result.orderNumber} id={data.result.id} onClick={approvedOrder}>Reject</button></>
                                            : ''}
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </section>
            <ToastContainer limit={1} />
        </>
    )
}

export default BPUOrderDetails